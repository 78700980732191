import React from "react";
import { Route as BaseRoute, Switch, withRouter, Link } from "react-router-dom";
import styled from "styled-components";

import Header from "./components/Header";
import Home from "./pages/Home";
import Contact from "./pages/Contact";

class ScrollToTopRoute extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      //window.scrollTo(0, 0);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return <BaseRoute {...rest} render={(props) => <Component {...props} />} />;
  }
}

const Route = withRouter(ScrollToTopRoute);

const AppFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainFlex = styled.main`
  padding-top: 80px;
  flex: 1;
  display: flex;
  min-height: calc(100vh - 80px);
`;

const LogoLink = styled(Link)`
  position: fixed;
  bottom: 40px;
  left: 40px;
`;

const Logo = styled.img`
  height: 50px;
  width: 50px;
`;

const TermsLink = styled(Link)`
  position: fixed;
  bottom: 40px;
  right: 40px;
`;

const NavButton = styled.span`
  display: inline-block;
  cursor: pointer;
  color: #232222;
  font-size: 10px;
  text-decoration: none;
  letter-spacing: 0.4px;
  position: relative;
  padding-bottom: 5px;
  text-transform: uppercase;
  :before {
    text-decoration: none;
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0px;
    left: 0;
    background-color: #232222;
    visibility: hidden;
    transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

function App() {
  return (
    <AppFlex>
      <Header />
      <MainFlex>
        <Switch>
          <Route path="/contact" component={Contact} />
          <Route
            path="/category/:cat/:slug?"
            component={(props) => (
              <Home
                category={props.match.params.cat}
                product={props.match.params.slug}
                {...props}
              />
            )}
          />
          <Route
            path="/:slug?"
            component={(props) => (
              <Home product={props.match.params.slug} {...props} />
            )}
          />
        </Switch>
      </MainFlex>
      <LogoLink to="/">
        <Logo src="/logo-black.png" />
      </LogoLink>
      <TermsLink to="/contact">
        <NavButton>CONTACT & TERMS OF SERVICE</NavButton>
      </TermsLink>
    </AppFlex>
  );
}

export default App;
