import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { StaggeredMotion, TransitionMotion, spring } from "react-motion";
import config from "../config";
import ProductPage from "./Product";
import { Helmet } from "react-helmet";

const Page = styled.div`
  width: 100%;
  margin-bottom: 100px;
  h2 {
    font-weight: 400;
    margin-bottom: -20px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-align: center;
  }
`;

const ProductGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex-flow: row wrap;
`;

const Product = styled(Link)`
  width: 300px;
  margin: 40px;
  height: auto;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  position: relative;
  &:hover h4:after {
    width: calc(100% + 8px);
  }
  /*&:hover h4 {
    color: white;
  }*/
  &:hover .image {
    animation: rainbow 1s linear 0s infinite;
  }
  @media (max-width: 1024px) {
    width: 35%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
  @keyframes rainbow {
    0% {
      -webkit-filter: hue-rotate(deg);
      filter: hue-rotate(deg);
    }
    100% {
      -webkit-filter: hue-rotate(2turn);
      filter: hue-rotate(2turn);
    }
  }
`;

const ProductImg = styled.div`
  width: 100%;
  display: block;
  padding-top: 130%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const ProductTitle = styled.h4`
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  color: #232222;
  position: relative;
  display: inline-block;
  margin: 10px auto 0;
  /*transition: color 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  &:after {
    content: "";
    display: block;
    overflow: hidden;
    position: absolute;
    top: -1px;
    left: -4px;
    background-color: #232222;
    width: 0;
    height: calc(100% + 2px);
    padding: 0;
    z-index: -1;
    transition: width 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  }*/
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Home = (props) => {
  let [products, setProducts] = useState([]);
  let [categories, setCategories] = useState([]);
  let [closing, setClosing] = useState(false);
  const history = useHistory();
  const close = function (slug) {
    return () => {
      if (window.location.href.includes(slug))
        history.push(props.category ? `/category/${props.category}` : "/");
      setClosing(true);
    };
  };

  useEffect(() => {
    if (props.category && categories.length > 0)
      window.sendEvent("viewCategory", {
        label: categories.filter((i) => i.slug === props.category)[0].name,
      });
    // eslint-disable-next-line
  }, [props.category, categories.length]);

  useEffect(() => {
    async function getProducts() {
      let cursor,
        plocal = [];
      do {
        const req = await axios.post(config.apiRoot, {
          query: `
        query getProducts($after: String, $first: Int) {
          products(after: $after, first: $first, where: {
            status: "publish",
            visibility: VISIBLE
        }) {
            nodes {
              name
              slug
              databaseId
              productCategories {
                nodes {
                  name
                  slug
                }
              }
              image {
                mediaItemUrl
              }
              galleryImages {
                nodes {
                  mediaItemUrl
                }
              }
              description
              type
              purchasable
              ...on SimpleProduct {
                price
                regularPrice
                stockStatus
                stockQuantity
              }
              ...on VariableProduct {
                price
                regularPrice
                attributes {
                  nodes {
                    label
                    variation
                    options
                  }
                }
                variations {
                  nodes {
                    name
                    id
                    databaseId
                    slug
                    price
                    purchasable
                    stockStatus
                    stockQuantity
                    attributes {
                      nodes {
                        name
                        label
                        value
                      }
                    }
                  }
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
          productCategories {
            nodes {
              name
              slug
            }
          }
        }
        `,
          variables: { after: cursor, first: 10 + plocal.length },
        });
        plocal = [
          ...plocal,
          ...req.data.data.products.nodes.filter(
            (i) => products.filter((x) => x.slug === i.slug).length === 0
          ),
        ];
        cursor = req.data.data.products.pageInfo.hasNextPage
          ? req.data.data.products.pageInfo.endCursor
          : null;
        setCategories(req.data.data.productCategories.nodes);
      } while (cursor);
      setProducts(plocal);
    }
    getProducts();
    // eslint-disable-next-line
  }, [setProducts]);

  const filteredProducts = props.category
    ? products.filter(
        (i) =>
          i.productCategories.nodes.filter((i) => i.slug === props.category)
            .length > 0
      )
    : products;

  const product =
    props.product && products.filter((i) => i.slug === props.product)[0];

  const cat =
    props.category && categories.filter((i) => i.slug === props.category)[0];

  return (
    <Page>
      <Helmet>
        <title>{cat ? cat.name + " | " : ""}Halcyon Shop</title>
      </Helmet>
      <TransitionMotion
        styles={
          product ? [{ key: product.slug, style: { h: spring(100) } }] : []
        }
        willEnter={() => {
          if (closing) setClosing(false);
          return { h: 0 };
        }}
        willLeave={() => ({ h: spring(0) })}
      >
        {(interpolatingStyle) => {
          return (
            <div>
              {interpolatingStyle.map((style) => (
                <ProductPage
                  product={product}
                  close={close(product?.slug)}
                  style={{
                    opacity: style.style.h + "%",
                    top: 100 - style.style.h + 80 + "px",
                    pointerEvents:
                      closing && style.style.h !== 100 ? "none" : "auto",
                  }}
                  key={style.key}
                />
              ))}
            </div>
          );
        }}
      </TransitionMotion>
      {props.category &&
        categories.filter((i) => i.slug === props.category)[0] && (
          <h2>{categories.filter((i) => i.slug === props.category)[0].name}</h2>
        )}
      {filteredProducts.length > 0 && (
        <StaggeredMotion
          key={props.category}
          defaultStyles={products.map((i) => ({ h: 0 }))}
          styles={(prevInterpolatedStyles) =>
            prevInterpolatedStyles.map((_, i) => {
              return i === 0
                ? { h: spring(100) }
                : { h: spring(prevInterpolatedStyles[i - 1].h) };
            })
          }
        >
          {(interpolatingStyles) => (
            <ProductGrid>
              {filteredProducts.map((i, q) => (
                <Product
                  to={
                    props.category
                      ? `/category/${props.category}/${i.slug}`
                      : `/${i.slug}`
                  }
                  key={i.slug}
                  style={{
                    opacity: interpolatingStyles[q]?.h + "%",
                    top: 100 - interpolatingStyles[q]?.h + "px",
                  }}
                  onClick={() => {
                    if (closing) setClosing(false);
                  }}
                >
                  <ProductImg className="image" src={i.image?.mediaItemUrl} />
                  <ProductTitle>{i.name}</ProductTitle>
                </Product>
              ))}
            </ProductGrid>
          )}
        </StaggeredMotion>
      )}
    </Page>
  );
};

export default Home;
