import React from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";
import axios from "axios";
import config from "../config";

const CartContainer = styled.div`
  z-index: 13;
  position: fixed;
  top: 20px;
  right: ${(props) =>
    props.visible ? "20px" : props.checkout ? "-100%" : "-25%"};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  height: calc(100% - 40px);
  width: ${(props) => (props.checkout ? "100%" : "25%")};
  border-radius: 8px;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15);
  background: white;
  transition: right 0.4s, opacity 0.4s, width 0.4s;
  font-size: 10px;
  overflow-y: auto;
  @media screen and (max-width: 1024px) {
    width: ${(props) => (props.checkout ? "100%" : "50%")};
    right: ${(props) =>
      props.visible ? "0" : props.checkout ? "-100%" : "-50%"};
  }
  @media screen and (max-width: 768px) {
    width: ${(props) => (props.checkout ? "100%" : "90%")};
    right: ${(props) =>
      props.visible ? "0" : props.checkout ? "-100%" : "-90%"};
  }
`;

const CartHeader = styled.h3`
  font-size: 10px;
  padding: 1rem;
  font-weight: 400;
`;

const CartItems = styled.div``;

const CheckoutArea = styled.div`
  padding: 1rem;
`;

const Subtotal = styled.div`
  font-size: 11px;
`;

const Button = styled.button`
  font-size: 10px;
  display: block;
  width: 100%;
  padding: 8px 5px;
  margin: 0 auto;
  border-radius: 0;
  line-height: 1;
  color: #fff;
  margin-top: 20px;
  text-decoration: uppercase;
  background-color: #232222;
  border: 0;
  outline: 0;
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    background: black;
  }
  &:disabled {
    background: #ccc;
    color: #232222;
  }
`;

const CartItem = styled.div`
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 15px;
  padding: 1em 0;
  display: flex;
  padding: 1rem;
`;

const CartItemPhoto = styled.div`
  background-image: url(${(props) => props.src});
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const CartItemText = styled.div``;

const Quantity = styled.input`
  font-family: "Barlow";
  font-size: 10px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin-top: 5px;
  color: black;
  border: none;
  padding: 4px 12px;
  text-align: center;
  :focus {
    border-color: black;
    outline: none;
    background: rgba(0, 0, 0, 0.1);
  }
`;

const CloseButton = styled.svg`
  top: 2em;
  right: 1em;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  opacity: 0.5;
  z-index: 15;
`;

const CartSegment = styled.div`
  position: relative;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  left: ${(props) => (props.visible ? "0" : "-100%")};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
  transition: opacity 0.5s, left 0.5s;
`;

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function Cart(props) {
  let [cartOpen, setCartOpen] = useGlobal("cartOpen");
  let [cart, setCart] = useGlobal("cart");

  const addToWooCart = async () => {
    let w = window.open();
    addInner(w);
  };

  const addInner = async (w) => {
    let items = cart.map((item) => {
      let obj = {};
      obj.productId = item.databaseId;
      obj.quantity = item.quantity;
      if (item.selectedVariant)
        obj.variationId = item.selectedVariant.databaseId;
      if (item.selectedAttributes)
        obj.variation = item.selectedAttributes.map((i) => ({
          attributeName: i.label.toLowerCase(),
          attributeValue: i.option,
        }));
      return obj;
    });
    const id = (
      await axios.post(
        "https://kian-wooswap.builtwithdark.com",
        JSON.stringify({ items })
      )
    ).data.id;
    w.location = config.checkoutUrl + "?id=" + id;
    w.focus();
  };

  Cart.handleClickOutside = () => setCartOpen(false);

  return (
    <CartContainer visible={cartOpen}>
      <CloseButton onClick={Cart.handleClickOutside} viewBox="0 0 24 24">
        <polygon
          id="x"
          points="18.717,6.697 17.303,5.283 12,10.586 6.697,5.283
			5.283,6.697 10.586,12 5.283,17.303 6.697,18.717 12,13.414 17.303,18.717 18.717,17.303 13.414,12 		"
        />
      </CloseButton>
      <CartSegment visible={true}>
        <CartHeader>CART</CartHeader>
        <CartItems>
          {(!cart || cart.length === 0) && (
            <CartItem>There are no items in your cart.</CartItem>
          )}
          {cart &&
            cart.map((item, index) => (
              <CartItem>
                <CartItemPhoto src={item.image.mediaItemUrl} />
                <CartItemText>
                  <div>{item.name}</div>
                  <div style={{ fontSize: "11px" }}>
                    {item.selectedVariant
                      ? item.selectedVariant.price
                      : item.price}
                  </div>
                  {item.selectedAttributes &&
                    item.selectedAttributes.map((i) => (
                      <div key={i.id}>
                        {i.label}: {i.option}
                      </div>
                    ))}
                  <Quantity
                    size="2"
                    value={item.quantity}
                    onBlur={(e) => {
                      let v = e.target.value;
                      if (!v) {
                        setCart(cart.filter((x, q) => q !== index));
                        window.sendEvent("removeFromCart", item.name);
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value === "")
                        return setCart(
                          cart.map((x, q) =>
                            q === index ? { ...x, quantity: "" } : x
                          )
                        );
                      let v = Number(e.target.value);
                      if (isNaN(v)) v = 1;
                      if (v === 0) {
                        setCart(cart.filter((x, q) => q !== index));
                        window.sendEvent("removeFromCart", item.name);
                      } else {
                        let maxQ;
                        console.log(item);
                        if (item.selectedVariant)
                          maxQ = item.selectedVariant.stockQuantity;
                        else maxQ = item.stockQuantity;
                        if (item.selectedVariant) {
                          if (
                            item.selectedVariant.stockStatus === "OUT_OF_STOCK"
                          )
                            maxQ = 0;
                        } else if (item.stockStatus === "OUT_OF_STOCK")
                          maxQ = 0;
                        setCart(
                          cart.map((x, q) =>
                            q === index
                              ? { ...x, quantity: maxQ && v > maxQ ? maxQ : v }
                              : x
                          )
                        );
                      }
                    }}
                  ></Quantity>
                </CartItemText>
              </CartItem>
            ))}
        </CartItems>
        {cart && cart.length > 0 && (
          <CheckoutArea>
            <Subtotal>
              Subtotal
              <br />
              {formatter.format(
                cart.reduce((p, c) => {
                  return (
                    p +
                    Number(
                      (c.selectedVariant
                        ? c.selectedVariant.price
                        : c.price
                      ).substr(1) * c.quantity
                    )
                  );
                }, 0)
              )}
            </Subtotal>
            <Button
              onClick={() => {
                window.sendEvent("startCheckout");
                addToWooCart();
              }}
            >
              Checkout
            </Button>
          </CheckoutArea>
        )}
      </CartSegment>
    </CartContainer>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Cart.handleClickOutside,
};

export default onClickOutside(Cart, clickOutsideConfig);
