import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import { Motion, spring } from "react-motion";
import onClickOutside from "react-onclickoutside";
import { Helmet } from "react-helmet";

/*
  border: 1px solid #232222;
*/

const ProductCard = styled.div`
  position: fixed;
  width: 920px;
  height: calc(100vh - 160px);
  max-height: 626px;
  margin: auto;
  top: 80px;
  left: 50%;
  margin-left: -460px;
  box-shadow: 0 1px 40px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  z-index: 6;
  padding: 20px;
  @media screen and (max-width: 1064px) {
    width: calc(100% - 120px);
    left: 40px;
    margin-left: 0;
  }
  @media screen and (max-width: 768px) {
    width: calc(100% - 80px);
    left: 20px;
    flex-direction: column;
    overflow-y: auto;
    top: 60px;
  }
`;

const ImgGalleryContainer = styled.div`
  width: calc(50% - 40px);
  vertical-align: top;
  display: inline-block;
  margin: 10px 10px;
  @media screen and (max-width: 768px) {
    width: calc(100% - 40px);
  }
`;

const MainImage = styled.div`
  background-image: url(${(props) => props.src});
  width: 100%;
  margin: 10px 10px 0;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: calc(100vh - 286px);
  max-height: 500px;
`;

const OptionImages = styled.div`
  display: flex;
  height: 100px;
`;

const OptionImage = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  width: 20%;
  margin: 10px;
  cursor: pointer;
`;

const InfoSection = styled.div`
  width: calc(50% - 40px);
  vertical-align: top;
  display: inline-block;
  margin: 20px 20px;
  text-align: right;
  @media screen and (max-width: 768px) {
    width: calc(100% - 40px);
  }
`;

const ProductTitle = styled.h3`
  font-size: 12px;
  font-weight: 400;
  margin: 0;
`;

const ProductDescription = styled.p`
  margin: 10px 0 0 0;
  font-size: 10px;
`;

const ProductPrice = styled.div`
  margin: 20px 0 0 0;
  font-size: 18px;
`;

const AddToCart = styled.button`
  font-size: 11px;
  display: block;
  width: 100%;
  padding: 8px 5px;
  margin: 0 auto;
  border-radius: 0;
  line-height: 1;
  color: #fff;
  margin-top: 20px;
  text-decoration: uppercase;
  background-color: #232222;
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  border: 0;
  outline: 0;
  cursor: pointer;
  &:hover {
    background: black;
  }
  &:disabled {
    background: #ccc;
    color: #232222;
  }
`;

const AttributeSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const AttributeTitle = styled.h6`
  font-size: 10px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 400;
`;

const AttributeOption = styled.div`
  display: inline-block;
  font-size: 10px;
  padding: 8px 20px;
  margin-left: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  color: ${(props) => (props.active ? "white" : "#232222")};
  background: ${(props) => (!props.active ? "white" : "#232222")};
  text-transform: uppercase;
  cursor: pointer;
`;

const CloseButton = styled.svg`
  top: 1em;
  right: 1em;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  opacity: 0.5;
`;

const ImageGallery = (props) => {
  let [activeImage, setActiveImage] = useState(props.images[0]);

  return (
    <ImgGalleryContainer>
      <MainImage src={activeImage.mediaItemUrl} />
      <OptionImages>
        {props.images.map((i) => (
          <OptionImage
            src={i.mediaItemUrl}
            onClick={() => setActiveImage(i)}
            key={i.mediaItemUrl}
          />
        ))}
      </OptionImages>
    </ImgGalleryContainer>
  );
};

// why is the woocommerce graphql API so bad
// the purpose of this function is to make these things matchable
const recombineVariants = (variations, attributes) => {
  const newAttributes = attributes.nodes.map((i, n) => ({ ...i, id: n }));
  const newVariants = variations.nodes.map((variant) => {
    const varattributes = variant.attributes.nodes.map((attr) => ({
      ...attr,
      id: newAttributes.filter((i) => i.label === attr.label)[0].id,
    }));
    return { ...variant, attributes: varattributes };
  });
  return { attributes: newAttributes, variants: newVariants };
};

function Product(props) {
  let [product] = useState(props.product);
  let [attrSelections, setAttrs] = useState([]);
  let [, setCartOpen] = useGlobal("cartOpen");
  let [cart, setCart] = useGlobal("cart");

  Product.handleClickOutside = props.close;

  let variantInfo;
  if (product?.type === "VARIABLE") {
    variantInfo = recombineVariants(product.variations, product.attributes);
  }

  const attrSetter = (id, label, option) => () => {
    let att = attrSelections.filter((i) => i.id === id)[0];
    if (att)
      setAttrs(attrSelections.map((i) => (i.id === id ? { ...i, option } : i)));
    else setAttrs([...attrSelections, { id, label, option }]);
  };

  const getActiveVariant = () => {
    let filteredVariants = variantInfo.variants;
    for (let attr of attrSelections) {
      filteredVariants = filteredVariants.filter((i) => {
        let varVal = i.attributes.filter((x) => x.id === attr.id)[0].value;
        return (
          !varVal ||
          varVal.toLowerCase().split(" ").join("").split("-").join("") ===
            attr.option.toLowerCase().split(" ").join("").split("-").join("")
        );
      });
    }
    return filteredVariants[0];
  };

  const addProduct = (product) => {
    window.sendEvent("addToCart", {
      label: product.name,
      id: product.databaseId,
    });
    if (cart) {
      if (
        cart.filter(
          (i) =>
            i.slug === product.slug &&
            (!product.selectedVariant ||
              (product.selectedVariant.id === i.selectedVariant.id &&
                JSON.stringify(product.selectedAttributes) ===
                  JSON.stringify(i.selectedAttributes)))
        )[0]
      )
        setCart(
          cart.map((i) =>
            i.slug === product.slug &&
            (!product.selectedVariant ||
              (product.selectedVariant.id === i.selectedVariant.id &&
                JSON.stringify(product.selectedAttributes) ===
                  JSON.stringify(i.selectedAttributes)))
              ? { ...i, quantity: Number(i.quantity) + 1 }
              : i
          )
        );
      else setCart([...cart, product]);
    } else setCart([product]);
  };

  useEffect(() => {
    if (props.product?.slug)
      window.sendEvent("viewItem", {
        label: product.name,
        id: product.databaseId,
      });
    // eslint-disable-next-line
  }, [props.product?.slug]);

  return (
    <Motion defaultStyle={{ h: 0 }} style={{ h: spring(100) }}>
      {(interpolatingStyle) => (
        <ProductCard
          style={{
            ...{
              opacity: interpolatingStyle.h + "%",
              top: 100 - interpolatingStyle.h + 80 + "px",
            },
            ...props.style,
          }}
        >
          <Helmet>
            <title>{product.name} | Halcyon Shop</title>
          </Helmet>
          <ImageGallery
            images={[product?.image, ...product.galleryImages.nodes]}
          />
          <CloseButton onClick={Product.handleClickOutside} viewBox="0 0 24 24">
            <polygon
              id="x"
              points="18.717,6.697 17.303,5.283 12,10.586 6.697,5.283
			5.283,6.697 10.586,12 5.283,17.303 6.697,18.717 12,13.414 17.303,18.717 18.717,17.303 13.414,12 		"
            />
          </CloseButton>
          <InfoSection>
            <ProductTitle>{product.name}</ProductTitle>
            <ProductDescription
              dangerouslySetInnerHTML={{ __html: product.description }}
            ></ProductDescription>
            {product.type === "SIMPLE" && product.purchasable && (
              <div>
                <ProductPrice>{product.price}</ProductPrice>
                <AddToCart
                  onClick={() => {
                    addProduct({ ...product, quantity: 1 });
                    setCartOpen(true);
                  }}
                >
                  ADD TO CART
                </AddToCart>
              </div>
            )}
            {product.type === "SIMPLE" && !product.purchasable && (
              <div>
                <ProductPrice>{product.price}</ProductPrice>
                <AddToCart disabled>SOLD OUT</AddToCart>
              </div>
            )}
            {product.type === "VARIABLE" && (
              <div>
                {variantInfo.attributes.map((attr) => (
                  <AttributeSelector key={attr.id}>
                    <AttributeTitle>{attr.label}</AttributeTitle>
                    <div>
                      {attr.options.map((i) => (
                        <AttributeOption
                          key={i}
                          onClick={attrSetter(attr.id, attr.label, i)}
                          active={
                            attrSelections.filter((i) => i.id === attr.id)[0]
                              ?.option === i
                          }
                        >
                          {i}
                        </AttributeOption>
                      ))}
                    </div>
                  </AttributeSelector>
                ))}
                {variantInfo.attributes.length === attrSelections.length &&
                  getActiveVariant() && (
                    <ProductPrice>{getActiveVariant().price}</ProductPrice>
                  )}
                {variantInfo.attributes.length === attrSelections.length &&
                  getActiveVariant() &&
                  product.purchasable &&
                  getActiveVariant().purchasable &&
                  product.stockStatus !== "OUT_OF_STOCK" &&
                  getActiveVariant().stockStatus !== "OUT_OF_STOCK" && (
                    <AddToCart
                      onClick={() => {
                        addProduct({
                          ...product,
                          quantity: 1,
                          selectedAttributes: attrSelections,
                          selectedVariant: getActiveVariant(),
                        });
                        setCartOpen(true);
                      }}
                    >
                      ADD TO CART
                    </AddToCart>
                  )}
                {variantInfo.attributes.length === attrSelections.length &&
                  (!product.purchasable ||
                    !getActiveVariant() ||
                    !getActiveVariant().purchasable ||
                    product.stockStatus === "OUT_OF_STOCK" ||
                    (getActiveVariant() &&
                      getActiveVariant().stockStatus === "OUT_OF_STOCK")) && (
                    <AddToCart disabled>SOLD OUT</AddToCart>
                  )}
              </div>
            )}
            {props.product?.slug == "sample-pack" && (
              <div>
                <iframe
                  width="100%"
                  height="20"
                  scrolling="no"
                  frameborder="no"
                  allow="autoplay"
                  style={{ marginTop: "20px" }}
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1109082268&color=%23ff90d7&inverse=false&auto_play=false&show_user=true%22%3E"
                ></iframe>
              </div>
            )}
            {props.product?.slug == "hssp01" && (
              <div>
                <iframe
                  width="100%"
                  height="20"
                  scrolling="no"
                  frameborder="no"
                  allow="autoplay"
                  style={{ marginTop: "20px" }}
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1214202052&color=%23ff90d7&inverse=false&auto_play=false&show_user=true%22%3E"
                ></iframe>
              </div>
            )}
            {props.product?.slug == "gagasounds" && (
              <div>
                <iframe
                  width="100%"
                  height="20"
                  scrolling="no"
                  frameborder="no"
                  allow="autoplay"
                  style={{ marginTop: "20px" }}
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1475971852&color=%23ff90d7&inverse=false&auto_play=false&show_user=true%22%3E"
                ></iframe>
              </div>
            )}
            {props.product?.slug == "hyperdeath" && (
              <div>
                <iframe
                  width="100%"
                  height="20"
                  scrolling="no"
                  frameborder="no"
                  allow="autoplay"
                  style={{ marginTop: "20px" }}
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1370557201&color=%23ff90d7&inverse=false&auto_play=false&show_user=true%22%3E"
                ></iframe>
              </div>
            )}
          </InfoSection>
        </ProductCard>
      )}
    </Motion>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Product.handleClickOutside,
};

export default onClickOutside(Product, clickOutsideConfig);
