import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../config";
import Cart from "./Cart";

const BannerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`;

const Banner = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  color: black;
  font-weight: bold;
  font-size: 14px;
`;

const Close = styled.svg`
  position: absolute;
  right: 20px;
  width: 16px;
  height: 16px;
  padding: 4px;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: ${(props) => (props.banner ? "60px" : "80px")};
  align-items: center;
  color: black;
  padding: 0 40px;
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  z-index: 10;
`;

const Nav = styled.div`
  display: flex;
`;

const NavButton = styled.span`
  display: block;
  float: left;
  clear: left;
  cursor: pointer;
  color: #232222;
  font-size: 10px;
  text-decoration: none;
  letter-spacing: 0.4px;
  position: relative;
  padding-bottom: 5px;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.6);
  padding: 5px 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  :before {
    text-decoration: none;
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0px;
    left: 0;
    background-color: #232222;
    visibility: hidden;
    transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

const NavDropdown = styled.div`
  position: absolute;
  top: 0px;
  padding-top: 30px;
  transform: ${(props) => (props.vis ? "scaleX(1)" : "scaleX(0)")};
  transform-origin: 0 0;
  transition: transform 0.2s;
  a {
    margin-bottom: 10px;
  }
`;

const NavButton2 = styled(NavButton.withComponent(Link))`
  cursor: default;
`;

const NavLink1 = NavButton.withComponent(Link);

const NavLink = styled(NavLink1)`
  display: block;
  float: left;
  clear: both;
  margin-left: -5px;
`;

const Spacer = styled.div`
  margin-right: 60px;
  @media screen and (max-width: 400px) {
    margin-right: 35px;
  }
`;

const Header = () => {
  let [showDropdown, setShowDropdown] = useState(false);
  let [categories, setCategories] = useState([]);
  let [, setCartOpen] = useGlobal("cartOpen");
  let [cart] = useGlobal("cart");
  let [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    async function getData() {
      const req = await axios.post(config.apiRoot, {
        query: `
        {
          productCategories {
            nodes {
              name
              slug
            }
          }
        }
        
        `,
      });
      setCategories(req.data.data.productCategories.nodes);
    }
    getData();
  }, [setCategories]);

  return (
    <BannerContainer>
      {showBanner && (
        <Banner>
          FREE shipping for USA orders over $150
          <Close viewBox="0 0 24 24" onClick={() => setShowBanner(false)}>
            <polygon
              id="x"
              points="18.717,6.697 17.303,5.283 12,10.586 6.697,5.283
			5.283,6.697 10.586,12 5.283,17.303 6.697,18.717 12,13.414 17.303,18.717 18.717,17.303 13.414,12 		"
            />
          </Close>
        </Banner>
      )}
      <HeaderContainer banner={showBanner}>
        <Nav>
          <NavButton2
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
            to="/"
          >
            PRODUCTS
            <NavDropdown vis={showDropdown}>
              {categories.map((i) => (
                <NavLink key={i.slug} to={`/category/${i.slug}`}>
                  {i.name}
                </NavLink>
              ))}
            </NavDropdown>
          </NavButton2>
          <Spacer />
          <NavButton onClick={() => setCartOpen(true)}>
            CART {cart && `(${cart.length})`}
          </NavButton>
        </Nav>
        <Cart />
      </HeaderContainer>
    </BannerContainer>
  );
};

export default Header;
