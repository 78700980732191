import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Page = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 100px;
  h2 {
    margin: 40px 0 20px 0;
  }
  h3,
  h4 {
    margin: 0;
  }
  a {
    color: black;
    display: inline-block;
    margin-bottom: 10px;
  }
`;

const Cookies = () => (
  <div id="cookies">
    <h2>Cookie Policy</h2>
    <p>
      Thanks for visiting Halcyon. Please take a moment to read our cookies
      Policy before continue using our website.
      <br />
      <br />
      This policy helps to explain the occasions when and why cookies may be
      sent to visitors to the website (referred to in this policy as "we", "us"
      or "our" or "Company").
      <br />
      <br />
      "Cookies" are text-only pieces of information that a website transfers to
      an individual’s hard drive or other website-browsing equipment for
      record-keeping purposes. Cookies allow the website to remember important
      information that will make your use of the site more convenient.
      <br />
      <br />
      <h3>What Are Cookies?</h3>
      <br />
      Cookies are small pieces of data, stored in text files, that are stored on
      your computer or other device when websites are loaded in a browser. They
      are widely used to "remember" you and your preferences, either for a
      single visit (through a "session cookie") or for multiple repeat visits
      (using a "persistent cookie"). They ensure a consistent and efficient
      experience for visitors, and perform essential functions such as allowing
      users to register and remain logged in. Cookies may be set by the site
      that you are visiting (known as "first party cookies"), or by third
      parties, such as those who serve content or provide advertising or
      analytics services on the website ("third party cookies").
      <br />
      <br />
      Both websites and HTML emails may also contain other tracking technologies
      such as "web beacons" or "pixels." These are typically small transparent
      images that provide us with statistics, for similar purposes as cookies.
      They are often used in conjunction with cookies, though they are not
      stored on your computer in the same way. As a result, if you disable
      cookies, web beacons may still load, but their functionality will be
      restricted.
      <br />
      <br />
      Cookies allow Halcyon to serve you better and more efficiently, and to
      personalize your experience on our Site. Web beacons, tags and scripts may
      be used in the website or in emails to help us to deliver cookies, count
      visits, understand usage and campaign effectiveness and determine whether
      an email has been opened and acted upon. We may analyze or perform
      analysis based on the use of these technologies and generate reports on an
      individual and aggregated basis.
      <br />
      <br />
      Cookies can be "persistent" or "session" cookies. A persistent cookie
      helps us recognize you as an existing user, so it’s easier to return to
      Halcyon or interact with our services without signing in again. After your
      visit, a persistent cookie stays in your browser and will be read by
      Halcyon when you return to one of our sites. Session cookies only last for
      as long as the session (usually the current visit to a website or a
      browser session).
      <br />
      <br />
      <h3>Why We Use Cookies</h3>
      <br />
      A cookie will typically contain the name of the domain from which the
      cookie has come, the "lifetime" of the cookie, and a randomly generated
      unique number or other value. Certain cookies will be used on the Site
      regardless of whether you are logged in to your account or not.  Like most
      websites, we use cookies for a variety of purposes in order to improve
      your online experience, for analytics and for marketing, but specifically
      we use cookies on the website for the following purposes:
      <br />
      <ul>
        <li>
          <strong>Analytical Purposes:</strong> Halcyon may use cookies to
          analyze user activity in order to improve the website. For example,
          using cookies we can look at aggregate patterns like the average
          number of searches that users perform. We can use such analysis to
          gain insights about how to improve the functionality and user
          experience of the website.
        </li>
        <li>
          <strong>Your Preferences:</strong> Halcyon uses cookies to store
          certain user preferences on our website. For example, we may store the
          recent searches you have performed in a Cookie so that we can allow
          you to easily repeat those searches when you return to our website.
        </li>
        <li>
          <strong>Marketing:</strong> We may use cookies from third-party
          partners such as Google and Facebook for marketing purposes. These
          cookies allow us to display Halcyon promotional material to you on
          other sites you visit across the Internet. We may also share
          information about your behavior on the website with third parties
          (including operators of third-party websites and/or social networking
          sites) in order to show you targeted advertisements and other content
          that has been customized for you.
        </li>
        <li>
          <strong>Referral Tracking:</strong> We may use cookies to associate
          user activity with the third-party website that referred the user to
          our website, or to associate user activity that Halcyon referred to a
          third-party website. These third-party partner websites receive credit
          for the activity of users they refer to our website, or give us credit
          for the activity of users referred to such third-party websites. We do
          not share any personal information or information about individual
          user activities with these partner entities.
        </li>
      </ul>
      <h3>Types Of Cookies We May Use In This Website</h3>
      <br />
      <strong>By purpose:</strong>
      <br />
      <ul>
        <li>
          <strong>Technical cookies:</strong> these are cookies that enable the
          user to browse the web page, platform or application and use the
          different options or service on it, e.g. to control traffic and data
          communication, to identify the session, to access restricted web site
          areas, to record elements that go to make up an order, to make a
          subscription or an application, to participate in an event, to use
          security elements during browsing, to store content to broadcast
          videos or sound or to share content over the social networks.
        </li>
        <li>
          <strong>Geolocation cookies:</strong> these cookies permit us to know
          from where the user is requesting a service. This cookie is anonymous
          and is used for example to offer you the proper information depending
          on the country where you are.
        </li>
        <li>
          <strong>Personalization cookies:</strong> these are cookies that
          enable the user to access the service pursuant to certain general,
          pre-defined characteristics according to a range of criteria on the
          user’s terminal, such as e.g., language, browser type used to access
          the service, regional configuration from where the service is
          accessed, etc.
        </li>
        <li>
          <strong>Analysis cookies:</strong> are those that enable its owner,
          monitoring and analyzing the behavior of users of the website to which
          they are linked. The information gathered through such cookies is used
          for measuring the activity of the website and for profiling navigation
          of users of the website, in order to improve the website based on
          those analysis.
        </li>
        <li>
          <strong>Functionality cookies:</strong> during your visit to the
          website, cookies are used to remember information you have entered or
          choices you make (such as your username, language or your region) on
          the website. They also store your preferences when personalizing the
          website to optimize your use of Halcyon. These preferences are
          remembered, through the use of the persistent cookies, and the next
          time you visit the website you will not have to set them again.
        </li>
      </ul>
      <strong>By duration:</strong>
      <br />
      <ul>
        <li>
          <strong>Session cookies:</strong> these are a type of cookie designed
          to collect and store information while the user is accessing a
          website. These cookies are not stored on the user’s computer when the
          session expires or the browser is closed.
        </li>
        <li>
          <strong>Persistent cookies:</strong> these are a type of cookie in
          which the data continue to be stored on the user’s computer and can be
          accessed and processed when the user leaves the website and when
          he/she returns to it. The user can delete these cookies at any time.
        </li>
      </ul>
      <strong>By creator:</strong>
      <br />
      <ul>
        <li>
          <strong>First-party cookies:</strong> Are those sent to the user’s
          terminal from a computer or domain managed by the owner of the website
          and from which the service requested by the user is provided.
        </li>
        <li>
          <strong>Third-party cookies:</strong> Are those sent to the user’s
          terminal from a computer or domain that is not managed by the owner of
          the website and from which the service requested by the user is
          provided but for another entity that processes the data gathered by
          the cookie. Likewise, in the event that cookies are installed from a
          computer or domain managed by the owner of the website itself but the
          information collected is managed by a third party, they will also be
          considered as third-party cookies.
        </li>
      </ul>
      Third party social networks (e.g., Facebook, YouTube, Twitter, LinkedIn
      etc.) may also place cookies. This enables you to share content of the
      website on social networks. These third parties may use cookies for their
      own purposes. Halcyon has no influence over how these social media
      networks make use of your data. For more information regarding the cookies
      set by the social media networks please refer to their own privacy and
      cookie policies.
      <br />
      <br />
      <h3>Browser Cookie Settings</h3>
      <br />
      Some people find the idea of a website storing information on their device
      a little intrusive, in particular when the information is stored and used
      by a third party. For example, you may object to being served advertising
      which is targeted to your interests based on your browsing history. If you
      would prefer to opt out of cookies, it is possible to control cookies by
      following the steps below, however you should be aware that you might lose
      some features and functionality of the website if you do so.
      <br />
      <br />
      Cookies, including those which have already been set, can be deleted from
      your hard drive. You can also change the preferences/settings in your web
      browser to control cookies. Some internet browsers have a 'Do Not Track'
      or 'DNT' setting; this sends a signal to websites asking them not to track
      your browsing.
      <br />
      <br />
      We have very limited control over these 'third party' cookies, so we
      suggest that you check the respective privacy policies for these external
      services to help you understand what data these organizations hold about
      you, what they do with it and how you can opt-out of the same.
      <br />
      <br />
      <h3>Revoking Consent</h3>
      <br />
      The user may at any time revoke consent in relation to this cookies Policy
      by deleting the cookies stored on the computer through the configuration
      and settings of your Internet browser, as listed above.
      <br />
      <br />
      <h3>Changes to the Cookie Policy</h3>
      <br />
      This cookies Policy may be amended at any time when requested by the
      applicable legislation or when there is a variation in the typology used
      on the website. We recommend you review this policy each time you visit
      our website in order to be properly informed about how and why we use
      cookies.
    </p>
  </div>
);

const Privacy = () => (
  <div id="privacy">
    <h2>Privacy Policy</h2>
    <p>
      Thanks for visiting Halcyon. Please take a moment to read our privacy
      policy before using this website.
      <br />
      <br />
      This Privacy Policy describes our policies and procedures on the
      collection, use, disclosure, and sharing of your personal information when
      you use this website.
      <br />
      <br />
      UNDER NO CIRCUMSTANCE WE SHALL HAVE ANY ACCOUNTABILITY TO YOU FOR ANY LOSS
      OR DAMAGE AS A RESULT OF THE USE OF ANY INFORMATION PROVIDED ON THE
      WEBSITE. YOUR USE OF THE WEBSITE AND YOUR RELIANCE ON ANY INFORMATION ON
      THE WEBSITE IS COMPLETELY AT YOUR OWN RISK.
      <br />
      <br />
      Our collection, use, disclosure, and processing of personal information
      about individuals will vary depending upon the circumstances. This privacy
      notice is intended to describe our overall privacy and data protection
      practices. In some cases, different or additional notices about our data
      collection and processing practices may be provided and apply to our
      processing of certain personal information.
      <br />
      <br />
      <h3>Section 1 - Why We Collect Your Information</h3>
      <br />
      We use the information we collect in various ways, including:
      <br />
      <ul>
        <li>To provide, operate, and maintain our website.</li>
        <li>To improve, personalize, and expand our website.</li>
        <li>To understand and analyze how you use our website.</li>
        <li>To develop new products, services, features, and functionality.</li>
        <li>
          To communicate with you, either directly or through one of our
          partners, including for user service, to provide you with updates and
          other information relating to the website, and for marketing and
          promotional purposes.
        </li>
        <li>To send you emails.</li>
        <li>To find and prevent fraud.</li>
      </ul>
      <h3>Section 2 - Our Collection of Personal Information</h3>
      <br />
      Personal information that we collect and process will vary depending upon
      the circumstances. We collect personal information directly from
      individuals, automatically related to the use of the Services. For
      example, some of the ways we collect information are as follows:
      Information we collect directly. We collect information that you provide
      to us, such as:
      <br />
      <ul>
        <li>
          when you provide your contact information and send other personal
          information to us;
        </li>
        <li>
          when you complete or submit a form through Halcyon, for example,
          "Contact Us" or "More Information" forms.
        </li>
      </ul>
      <h4>Your Data Controller</h4>
      <br />
      We are the data controller with respect to processing your data. This
      means that we decide how your personal data is processed and for what
      purposes. We know that you care how data about you is used and shared, and
      we appreciate your trust that we will do so carefully and sensibly.
      <br />
      <br />
      This Privacy Policy applies to activities by Halcyon and its websites and
      subsidiaries (collectively "Company", "we" or "us").
      <br />
      <br />
      <h4>Your Consent</h4>
      <br />
      If you revoke your consent for the processing of Personal Information,
      then we may no longer be able to provide you services. In some cases, we
      may limit or deny your request to revoke consent if the law permits or
      requires us to do so, or if we are unable to adequately verify your
      identity.
      <br />
      <br />
      <h4>Your Choices</h4>
      <br />
      You may, of course, decline to submit information through our website, in
      which case we may not be able to provide related services to you.
      <br />
      <br />
      <h3>Section 3 - How We Use Your Personal Information</h3>
      <br />
      While the purposes for which we may process personal information will vary
      depending upon the circumstances, in general, we use personal information
      for the purposes set forth below.
      <br />
      <ol>
        <li>
          <strong>Providing support and services:</strong> including, for
          example, to provide services you request (and send related
          information), operate Halcyon to communicate with you about your
          access to and use of our services; to respond to your inquiries; to
          provide troubleshooting, fulfill your requests and provide technical
          support; and for other user service and support purposes.
        </li>
        <li>
          <strong>Analyzing and improving our business:</strong> including to
          better understand how users access and use Halcyon to evaluate and
          improve our services and business operations, and to develop new
          products, features, offerings, and services; to conduct surveys and
          other evaluations, such as customer satisfaction surveys; and for
          other research and analytical purposes.
        </li>
        <li>
          <strong>Personalizing content and experiences:</strong> including to
          provide or recommend features, content, social connections, and
          referrals; tailor content we send or display on Halcyon to offer
          customization and personalized help and instructions, and otherwise
          personalize your experiences.
        </li>
        <li>
          <strong>Advertising, marketing, and promotional purposes:</strong>
          including to reach you with more relevant ads and to evaluate,
          measure, and improve the effectiveness of our ad campaigns; to send
          you newsletters, offers, or other information we think may interest
          you; and to contact you about Halcyon or information we think may
          interest you.
        </li>
        <li>
          <strong>Defending our legal rights:</strong> including to manage and
          respond to actual and potential legal disputes and claims, and to
          otherwise establish, defend or protect our rights or interests,
          including in the context of anticipated or actual litigation with
          third parties.
        </li>
        <li>
          <strong>Complying with legal obligations:</strong> including to comply
          with the law, our legal obligations and legal process, such warrants,
          subpoenas, court orders, and regulatory or law enforcement requests.
        </li>
      </ol>
      <h3>Section 4 - Disclosure of Personal Information</h3>
      <br />
      We may disclose the personal information that we collect about you as set
      forth below or as otherwise described at the time of collection or
      sharing.
      <br />
      <ol>
        <li>
          <strong>Service providers:</strong> We may disclose personal
          information with third-party service providers who use this
          information to perform services for us, such as hosting providers,
          instructors, advisors, consultants, and user service and support
          providers.
        </li>
        <li>
          <strong>Third Parties:</strong> We may employ other companies and
          individuals to perform functions on our behalf. Examples include
          sending postal mail and e-mail, removing repetitive information from
          user lists, analyzing data, providing marketing assistance, processing
          payments, transmitting content, and providing user service. These
          third-party service providers have access to personal information
          needed to perform their functions but may not use it for other
          purposes.
        </li>
        <li>
          <strong>Business transfers:</strong> We may disclose or transfer
          personal information as part of any actual or contemplated merger,
          sale, and transfer of our assets, acquisition, financing, or
          restructuring of all or part of our business, bankruptcy, or similar
          event, including related to due diligence conducted before such event
          where permitted by law.
        </li>
        <li>
          <strong>Legally required:</strong> We may disclose personal
          information if we are required to do so by law (e.g., to law
          enforcement, courts, or others, e.g., in response to a subpoena or
          court order).
        </li>
        <li>
          <strong>Protect our rights:</strong> We may disclose personal
          information where we believe it necessary to respond to claims
          asserted against us or, comply with legal process (e.g., warrants),
          enforce or administer our agreements and terms, for fraud prevention,
          risk assessment, investigation, and to protect the rights, property,
          or safety of us, our clients and users or others.
        </li>
      </ol>
      <h3>Section 5 - Cookies</h3>
      <br />
      Cookies are small text files that a website transfers to your hard drive
      to store and sometimes collect information about your usages of websites,
      such as time spent on the websites, pages visited, language preferences,
      and other anonymous traffic data. You can control the way in which cookies
      are used by altering your browser settings. You may refuse to accept
      cookies by activating the setting on your browser that allows you to
      reject cookies. However, if you select such a setting, this may affect the
      functioning of the site. Unless you have adjusted your browser setting so
      that it will refuse cookies, our system will issue cookies when you access
      or log on to our Service.
      <br />
      <br />
      <h3>Section 6 - Log Files</h3>
      <br />
      Most browsers collect certain information, such as your IP address, device
      type, screen resolution, operating system version, and internet browser
      type and version, which is transmitted when you access our website. This
      information is gathered automatically and stored in log files.
      <br />
      <br />
      <h3>Section 7 - Third-Party Analytics Tools</h3>
      <br />
      Our website may use automated devices and applications operated by third
      parties, such as Google Analytics, which uses cookies and similar
      technologies to collect and analyze information about the use of the
      website and report on activities and trends. This service may also collect
      information regarding the use of other websites, apps, and online
      resources.
      <br />
      <br />
      <h3>Section 8 - Do-Not-Track Signals</h3>
      <br />
      Please note that our website does not recognize or respond to any signal
      which your browser might transmit through the so-called 'Do Not Track'
      feature your browser might have. If you wish to disable cookies on our
      website, you should not rely on any 'Do Not Track' feature your browser
      might have.
      <br />
      <br />
      <h3>Section 9 - Security</h3>
      <br />
      We use technical, administrative, and physical controls in place to help
      protect Personal Information from unauthorized access, use, and
      disclosure. Even so, despite our reasonable efforts, no security measure
      is ever perfect or impenetrable.
      <br />
      <br />
      <h3>Section 10 - Do Not Sell My Data</h3>
      <br />
      Except as otherwise stated in this Privacy Policy, we do not sell, trade,
      rent, or otherwise share for marketing purposes your Personal Information
      with third parties without your consent. We will retain your personal
      information for the period necessary to fulfill the purposes outlined in
      this privacy notice unless a longer retention period is required or
      permitted by law. We may retain personal information for longer where
      required by our legal and regulatory obligations, professional indemnity
      obligations, or where we believe it is necessary to establish, defend or
      protect our legal rights and interests or those of others. With respect to
      the data and files we handle as a processor, we retain this personal
      information in accordance with our clients' instructions.
      <br />
      <br />
      <h3>Section 11 - CCPA Privacy Rights</h3>
      <br />
      Under the CCPA, among other rights, California consumers have the right
      to:
      <br />
      <ul>
        <li>
          Request that a business that collects a consumer's personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </li>
        <li>
          Request that a business deletes any personal data about the consumer
          that a business has collected.
        </li>
        <li>
          Request that a business that sells a consumer's personal data not sell
          the consumer's personal data.
        </li>
      </ul>
      The categories of data we collect are disclosed in this privacy policy,
      and we do not sell your personal data. To request that we remove your
      data, please contact us.
      <br />
      <br />
      <h3>Section 12 - California Online Privacy Protection Act</h3>
      <br />
      According to CalOPPA we agree to the following:
      <br />
      <ul>
        <li>Users can visit our site anonymously.</li>
        <li>
          Once this privacy policy is created, we will add a link to it on our
          home page, or as a minimum on the first significant page after
          entering our website.
        </li>
        <li>
          Our Privacy Policy link includes the word 'Privacy', and can be easily
          be found on the page specified above.
        </li>
      </ul>
      <h3>Section 13 - GDPR Data Protection Rights</h3>
      <br />
      We would like to make sure you are fully aware of all of your data
      protection rights. Every European user is entitled to the following:
      <br />
      <ol>
        <li>
          <strong>Right to access:</strong> If you can prove your identity, you
          have the right to obtain information about the processing of your
          data. Thus, you have the right to know the purposes of the processing,
          the categories of data concerned, the categories of recipients to whom
          the data are transmitted, the criteria used to determine the data
          retention period, and the rights that you can exercise on your data.
        </li>
        <li>
          <strong>Right to rectification of your personal data:</strong>{" "}
          Inaccurate or incomplete personal data may be corrected.
        </li>
        <li>
          <strong>Right to erasure (or "right to be forgotten"):</strong> You
          also have the right to obtain the erasure of your personal data under
          the following assumptions:
          <br />
          <br />
          - Your personal data are no longer necessary for the intended
          purposes;
          <br />- You withdraw your consent to the processing and there is no
          other legal ground for processing; <br />- You have validly exercised
          your right of opposition; <br />- Your data has been illegally
          processed;
          <br /> - Your data must be deleted to comply with a legal obligation.
          <br />
          <br />
          The deletion of data is mainly related to visibility; it is possible
          that the deleted data are still temporarily stored.
        </li>
        <li>
          <strong>Right to limitation of processing:</strong> In certain cases,
          you have the right to request the limitation of the processing of your
          personal data, especially in case of dispute as to the accuracy of the
          data, if the data are necessary for the context of legal proceedings
          or the time required to verify that you can validly exercise your
          right to erasure.
        </li>
        <li>
          <strong>Right to object:</strong> You have the right to object at any
          time to the processing of your personal data for direct marketing
          purposes. We will stop processing your personal data unless it can
          demonstrate that there are compelling legitimate reasons for the
          processing which prevail over your right to object.
        </li>
        <li>
          <strong>Right to data portability:</strong> You have the right to
          obtain any personal data which you have provided us in a structured,
          commonly used, and machine-readable format. You are then free to
          transfer this data to a similar service provider.
        </li>
        <li>
          <strong>Right to withdraw your consent:</strong> You may withdraw your
          consent to the processing of your personal data at any time, for
          example for personalized marketing communication purposes.
        </li>
      </ol>
      <h3>Section 14 - Third-Party Websites</h3>
      <br />
      The Services may contain links to third-party services. We do not own,
      operate, or control the websites of third-parties, including those of
      independently owned and operated franchisees. Accordingly, this Policy
      does not apply to any services maintained or operated by third-parties.
      When you click on those links, you will go to a third-party website where
      you will be subject to that service's privacy policy or similar statement
      and terms of use, and we encourage you to read that policy statement. We
      are not responsible for the privacy practices of other services, and we
      expressly disclaim any liability for their actions, including actions
      related to the use and disclosure of personal information by those third
      parties.
      <br />
      <br />
      <h3>Section 15 - Changes to this Policy</h3>
      <br />
      We may amend this Policy at any time. If we make any material change in
      how we collect, use, disclose or otherwise process personal information,
      we will prominently post a notice regarding such change on the Services.
      Where required to do so by law, we may seek your prior consent to any
      material changes we make to this Policy.
    </p>
  </div>
);

const Refund = () => (
  <div id="refund">
    <h2>Refund & Cancellation Policy</h2>
    <p>
      Thank you for shopping at Halcyon. If you are not completely satisfied
      with a purchase we invite you to review our policy on refunds and returns.
      The following terms are applicable for any products that you purchased
      with us.
      <br />
      <br />
      <h3>Interpretation</h3>
      <br />
      The words of which the initial letter is capitalized have meanings defined
      under the following conditions. The following definitions shall have the
      same meaning regardless of whether they appear in singular or in plural.
      <br />
      <br />
      <h3>Definitions</h3>
      <br />
      For the purposes of this Refund Policy:
      <br />
      <ul>
        <li>
          <strong>You</strong> means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </li>
        <li>
          <strong>Company</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to Halcyon.
        </li>
        <li>
          <strong>Website</strong> refers to this website.
        </li>
        <li>
          <strong>Product</strong> refers to the products offered for sale on
          the website.
        </li>
        <li>
          <strong>Orders</strong> means a request by You to purchase products
          from Us.
        </li>
      </ul>
      <h3>Refund Policy</h3>
      <br />
      Any product, once received by you, can be returned to us for a refund or
      replacement within 30 days of delivery of the package if:
      <br />
      <ul>
        <li>You received a damaged/faulty product;</li>
        <li>You received a wrong product;</li>
        <li>Your product didn't arrive;</li>
        <li>You are not satisfied with your product.</li>
      </ul>
      <strong>
        If you received a damaged product, please contact us with proof as
        below:
      </strong>
      <br />
      <ul>
        <li>The photos of original package with front and back sides.</li>
        <li>The photos or video of the defective product(s).</li>
        <li>
          The photos, video or screenshot showing the malfunction of the
          defective product.
        </li>
      </ul>
      We will usually offer you a refund or send a replacement, as soon as the
      problem is confirmed.
      <br />
      <br />
      <strong>
        Return of or refund for purchased Products shall not be entertained by
        Halcyon if:
      </strong>
      <br />
      <ul>
        <li>The Product has been used or altered in any manner;</li>
        <li>
          The price tags, brand tags, box, original packaging material, and
          accompanying accessories have been damaged or discarded by the User;
        </li>
        <li>
          The Product is not accompanied by the original invoice of purchase;
        </li>
        <li>
          The serial number/IMEI number/bar code of the Product, as applicable,
          does not match our records;
        </li>
        <li>
          The accessories delivered with the Product are not returned along with
          the Product, in an undamaged condition;
        </li>
        <li>
          The product was marked as non refundable, clearance sale/final sale,
          gift or promotional item;
        </li>
        <li>
          There are any major stains, dents, scratches, tears or any other
          damage to the Products or any part thereof;
        </li>
        <li>The Product doesn’t reach us in sellable condition;</li>
        <li>
          Halcyon is satisfied that the Product has been rendered used,
          defective or unusable.
        </li>
      </ul>
      Refunds will only be issued for damaged products if they were received by
      You in damaged condition. We reserve the right to refuse returns of any
      product that does not meet the refund conditions in our sole discretion.
      We will usually offer you a refund or send a replacement as soon as we
      receive the product from you. This process might take 14 days to a month
      depending upon the different scenarios. You shall be responsible for the
      return shipping charges for orders outside the United States.
      <br />
      <br />
      <h3>Cancellation Policy</h3>
      <br />
      You can cancel your order before the product has been shipped. Amount paid
      will be refunded back to the same mode which was chosen by you at the time
      of making the payment. Unfortunately, an order cannot be cancelled once
      the item has been shipped from our warehouse.
    </p>
  </div>
);

const Shipping = () => (
  <div id="shipping">
    <h2>Shipping Policy</h2>
    <p>
      Thanks for shopping at Halcyon.
      <br />
      <br />
      After your purchase has been confirmed, it will take 1-3 business days to
      process your order and should be delivered to your preferred location
      within 7-15 business days.
      <br />
      <br />
      All international orders once dispatched usually take anywhere between 2
      to 4 weeks to reach their destination. We use courier companies which
      provide tracking information and keep you updated regarding the same after
      your placed order has been processed.
      <br />
      <br />
      All tracking numbers will be emailed once the order is processed.
      International shipments may be subject to import taxes and duties by the
      destination country upon receipt, which are the sole responsibility of the
      buyer.
      <br />
      <br />
      Shipping costs will be calculated at the time of checkout and may vary
      depending on weight, postal code, and shipping method. Shipping to remote
      locations may require additional time for delivery.
      <br />
      <br />
      Please be reminded that you may have to sign acceptance for your order
      when it is delivered. If you are unavailable at the time of delivery, your
      order will be couriered to you on the next available working day.
      <br />
      <br />
      If you are not available to accept delivery of your shipment, the assigned
      courier company will provide a notification card advising you of the
      process for redelivery. If three (3) attempts in total are made to deliver
      your order, it will be returned to us. Redelivery will be at the expense
      of the customer.
      <br />
      <br />
      <h3>Delays In Delivery</h3>
      <br />
      We may provide shipping time estimations for all of our products. We will
      work to get your order to you as quickly as possible, but please note that
      our shipping lead times are all estimations and we cannot guarantee an
      order to arrive by a specific time.
      <br />
      <br />
      Estimated delivery dates are indicative & approximate values only and
      items can be shipped earlier or later depending on availability of item
      and other factors. No refund, returns, replacement & exchange will be
      entertained for this reason.
      <br />
      <br />
      Certain products purchased during this time may be delayed due to business
      interruptions due to local regulations regarding COVID-19. We are doing
      our best to estimate any potential delays in shipments on our product
      pages. We appreciate your patience and understanding at this time.
      <br />
      <br />
      In rare cases, delivery may take longer for orders coming from overseas
      due to:
      <br />
      <ul>
        <li>Bad weather</li>
        <li>Flight delays</li>
        <li>National holidays or festivals</li>
        <li>Order fulfillment process</li>
        <li>Customs clearance procedures</li>
        <li>Other unforeseen circumstances</li>
      </ul>
      While we will use our best efforts to fulfill all orders, we cannot
      guarantee the availability of any particular product displayed on the
      Site. We reserve the right to discontinue the sale of any product listed
      on the Site at any time without notice.
      <br />
      <br />
      We reserve the right, at our sole discretion, to refuse or cancel any
      order for any reason.
      <br />
      <br />
      In case of internal disturbances in the recipient destination (war,
      strife, riots etc.), we reserve the right to cancel an order and refund
      the money if we feel that a package cannot be delivered safely.
    </p>
  </div>
);

const Terms = () => (
  <div id="terms">
    <h2>Terms and Conditions</h2>
    <p>
      Welcome to Halcyon and thank you for visiting our website.
      <br />
      <br />
      When you use our products and services, you're agreeing to our terms. So
      please take a few minutes to read over the below mentioned Terms and
      Conditions before using our website.
      <br />
      <br />
      <h3>Section 1 - Acceptance</h3>
      <br />
      By clicking "I AGREE" and/or simply by using or accessing our services and
      this website, you hereby agree,
      <br />
      <br />
      (a) that you have received, read and understood these Terms and
      Conditions, and that these Terms and Conditions create a valid and binding
      agreement, enforceable against you in accordance with the terms hereof,
      <br />
      <br />
      (b) to be bound by these Terms and Conditions, any terms, conditions or
      other rules, regulations or policies of Halcyon, as each may be amended or
      supplemented from time to time in our sole discretion without notice;
      <br />
      <br />
      (c) that your use of our services and our website shall comply with all
      applicable federal and local laws, rules or regulations, and that you are
      solely responsible for your compliance with, familiarity with and
      understanding of any such laws, rules or regulations applicable to your
      use of the site.
      <br />
      <br />
      If you do not agree with any portion of these Terms and Conditions, you
      are prohibited from using or accessing our services.
      <br />
      <br />
      <h3>Section 2 - Proprietary Rights</h3>
      <br />
      As between you and us, we own, solely and exclusively, all rights, title
      and interest in and to the site, all the content (including, for example,
      audio, photographs, illustrations, graphics, other visuals, video and
      copy), software, code, data, and the look and feel, design and
      organization of the site, and all materials and content related to our
      programs even if the materials or content are not accessed through the
      site. Your use of the site does not grant to you ownership of any content,
      software, code, data or materials you may access on the site.
      <br />
      <br />
      <h3>Section 3 - Prohibited Conduct</h3>
      <br />
      Without limiting the generality of the foregoing or any other provision
      hereof, you acknowledge and agree, as follows:
      <br />
      <ol>
        <li>
          Not to access the Site using any automated means, including, without
          limitation, harvesting bots, robots, spiders, or scrapers;
        </li>
        <li>
          Not to engage in multi-level marketing using the Site, including,
          without limitation, pyramid schemes, and similar marketing concepts;
        </li>
        <li>
          Not to upload, use or disseminate viruses or other malicious code or
          other abusive scripts or processes;
        </li>
        <li>
          Not to solicit personal information of another person or request or
          obtain access to an account of another person;
        </li>
        <li>Not to bully, intimidate, or harass any person;</li>
        <li>
          Not to use the Site in any manner that is, or could reasonably be
          construed to be, in violation of these Terms and Conditions,
          fraudulent, misleading, malicious or discriminatory;
        </li>
        <li>
          Not to take any action that could disable, overburden, or impair the
          operation or availability of the Site, such as a denial-of-service
          attack;
        </li>
        <li>
          Not to engage in manipulative practices designed to obfuscate the true
          intent of your submissions to the Site, or to artificially generate
          traffic to another website;
        </li>
        <li>
          Not to facilitate or encourage any violations of these Terms and
          Conditions;
        </li>
        <li>Not to issue chargeback disputes against us;</li>
        <li>
          Not to use patented, copyrighted, trademarked or other protected
          intellectual property without the written consent and authorization of
          the owner of such property;
        </li>
        <li>
          Not to copy, distribute or disseminate the Site or any portion
          thereof, and not to transfer the Site, or any portion thereof, to
          another person or "mirror" the Site, or any portion thereof, on any
          other server;
        </li>
        <li>
          Not to decompile or reverse engineer, or attempt to decompile or
          reverse engineer, the Site or any portion thereof.
        </li>
      </ol>
      <br />
      <h3>Section 4 - Payment and Prices</h3>
      <br />
      Prices for products are quoted on the item listing. We will verify prices
      as part of our sale procedures so that a product's correct price will be
      stated when you pay for the product. In addition to the price of the
      products, you may have to pay a delivery charge, which will be as stated
      when you pay for the product.
      <br />
      <br />
      Product descriptions, typographic, pricing and photographic errors are
      unintentional and subject to correction. We regret, but are not liable for
      such errors. We reserve the right to reject any orders placed for a
      product listed at an incorrect price. If you discover an error in our
      website or catalogue, please let us know.
      <br />
      <br />
      Prices for products are liable to change at any time, but changes will not
      affect contracts which have come into force. If you dispute any payment
      made to us you must contact us immediately and provide full details of
      your claim.
      <br />
      <br />
      <h3>Section 5 - Refusal of your Order</h3>
      <br />
      We make an earnest effort to make sure that products listed on our website
      is available in stock and that the prices shown are correct. In the
      unlikely event when we’re not able to send your order or replacement due
      to unavailability of the product in our warehouse, we will inform you
      within a maximum of 7 working days from the date you place the order.
      <br />
      <br />
      <h3>Section 6 - Personal Responsibility</h3>
      <br />
      You aim to accurately represent the information provided to us on or
      through our website. You acknowledge that you are participating
      voluntarily in using our website and that you are solely and personally
      responsible for your choices, actions and results, now and in the future.
      You accept full responsibility for the consequences of your use, or
      non-use, of any information provided on or through this website, and you
      agree to use your own judgment and due diligence before purchasing
      anything from our website.
      <br />
      <br />
      <h3>Section 7 - External Links</h3>
      <br />
      From our website, you can visit other websites by following hyperlinks to
      such external sites. While we strive to provide only quality links to
      useful and ethical websites, we have no control over the content and
      nature of these sites. These links to other websites do not imply a
      recommendation for all the content found on these sites. Site owners and
      content may change without notice and may occur before we have the
      opportunity to remove a link that may have gone 'bad'.
      <br />
      <br />
      WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE
      ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES
      LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR
      OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE
      FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF
      PRODUCTS OR SERVICES.
      <br />
      <br />
      <h3>Section 8 - Limitations on Use</h3>
      <br />
      You may not reproduce, resell, or distribute our products for any purpose
      unless you have been specifically permitted to do so under a separate
      agreement with Halcyon. You may not sell or enable any third parties to
      resell the products purchased by You, display on any website or otherwise
      publish the services or any Content obtained from a Service or otherwise
      generate income from the services or use the services for the development,
      production or marketing of a service or product substantially similar to
      our services.
      <br />
      <br />
      <h3>Section 9 - Indemnification</h3>
      <br />
      You agree to defend, indemnify and hold us and our directors, officers,
      employees and agents harmless from any and all claims, liabilities, costs
      and expenses, including reasonable attorneys' fees, arising in any way
      from any content or other material you place on the site or submit to us,
      or your breach or violation of the law or of these Terms and Conditions.
      We reserve the right, at our own expense, to assume the exclusive defense
      and control of any matter otherwise subject to indemnification by you, and
      in such case, you agree to cooperate with our defense of such claim.
      <br />
      <br />
      <h3>Section 10 - Disclaimer of Warranties</h3>
      <br />
      WE DO NOT: (I) GUARANTEE THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY
      THIRD-PARTY CONTENT ON THE SITE OR SERVICES (II) ADOPT, ENDORSE OR ACCEPT
      RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR
      STATEMENT MADE BY ANY PARTY THAT APPEARS ON THE SITE. UNDER NO
      CIRCUMSTANCES WILL WE BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE
      RESULTING FROM YOUR RELIANCE ON INFORMATION OR OTHER CONTENT POSTED ON OR
      AVAILABLE FROM THE SITE.
      <br />
      <br />
      <h3>Section 11 - Limitation of Liability</h3>
      <br />
      IN NO EVENT, INCLUDING BUT NOT LIMITED TO NEGLIGENCE, WILL WE OR ANY OF
      OUR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR CONTENT OR SERVICE PROVIDERS
      (COLLECTIVELY, THE "PROTECTED ENTITIES") BE LIABLE FOR ANY INDIRECT,
      SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING
      FROM, OR DIRECTLY OR INDIRECTLY RELATED TO, THE USE OF, OR THE INABILITY
      TO USE, THE SITE OR THE CONTENT, MATERIALS, PRODUCTS, SERVICES, AND
      FUNCTIONS RELATED TO THE SITE, YOUR PROVISION OF INFORMATION VIA THE SITE,
      LOST BUSINESS OR LOST SALES, EVEN IF SUCH PROTECTED ENTITY HAS BEEN
      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT
      ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
      CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO
      CERTAIN USERS TO THE EXTENT REQUIRED BY APPLICABLE LAW.
      <br />
      <br />
      <h3>Section 12 - Termination</h3>
      <br />
      We may terminate, change, suspend or discontinue any aspect of the site or
      the site's products or services at any time. We may restrict, suspend or
      terminate your access to the site and/or its products or services if we
      believe you are in breach of these Terms and Conditions or applicable law,
      you are a repeat infringer of intellectual property rights, or for any
      other reason without notice or liability.
      <br />
      <br />
      <h3>Section 13 - Waivers</h3>
      <br />
      Our failure to act with respect to a breach of these Terms and Conditions
      by you or others does not waive our right to act with respect to that
      breach or subsequent similar or other breaches.
      <br />
      <br />
      <h3>Section 14 - Force Majeure</h3>
      <br />
      Neither party hereto shall be responsible for delays or failures in
      performance resulting from acts beyond its reasonable control and without
      its fault or negligence. Such excusable delays or failures may be caused
      by, among other things, strikes, lock-out, riots, rebellions, accidental
      explosions, floods, storms, acts of God and similar occurrences.
      <br />
      <br />
      <h3>Section 15 - Governing Law and Jurisdiction</h3>
      <br />
      These Terms and Conditions will be governed by and construed in accordance
      with American laws, and the courts of Nevada will have non-exclusive
      jurisdiction to adjudicate any dispute arising under or in relation to
      these terms of sale.
      <br />
      <br />
      <h3>Section 16 - Copyright</h3>
      <br />
      &copy; 2021 Halcyon. All rights reserved. All materials presented on this
      site are copyrighted and owned by Halcyon. or other individuals or
      entities as designated. Any republication, retransmission, reproduction,
      downloading, storing or distribution of all or part of any materials found
      on this site is expressly prohibited.
      <br />
      <br />
      <h3>Section 17 - Modifications</h3>
      <br />
      We reserve the right, at our sole discretion, to modify any portion of
      these Terms and Conditions at any time. Changes in these Terms and
      Conditions will be effective when posted. Your continued use of the site
      and/or the products or services offered on or through the site after any
      changes to these Terms and Conditions are posted will be considered
      acceptance of those changes.
      <br />
      <br />
      <h3>Section 18 - Accepting these Terms of Use</h3>
      <br />
      You hereby accept the fact that you have read, understood and are willing
      to abide by the terms and conditions laid down in this agreement. You
      further agree that the terms and conditions set out under this agreement
      are fair, reasonable and just given the matters set out under this
      agreement and you waive any and all rights to have any claims against us
      on grounds set out above.
    </p>
  </div>
);

const Contact = (props) => {
  return (
    <Page>
      <Helmet>
        <title>Contact & Terms of Service | Halcyon Shop</title>
      </Helmet>
      <h2 style={{ textAlign: "center" }}>SUPPORT / CONTACT US</h2>
      <p style={{ textAlign: "center" }}>
        Email us at <strong>shop@halcyon.fm</strong>.
      </p>
      <div>
        <a href="#terms">Terms of Service</a>
        <br />
        <a href="#refund">Refund Policy</a>
        <br />
        <a href="#shipping">Shipping Policy</a>
        <br />
        <a href="#privacy">Privacy Policy</a>
        <br />
        <a href="#cookies">Cookies Policy</a>
      </div>
      <Terms />
      <Refund />
      <Shipping />
      <Privacy />
      <Cookies />
    </Page>
  );
};

export default Contact;
